import styled, { css } from 'styled-components'

// Types
import { SwiperWrapperProps } from './Swiper.types'

export const SwiperWrapper = styled.div<SwiperWrapperProps>`
  position: relative;
  max-width: 100vw;
  z-index: 10;

  ${({ service }) =>
    service &&
    css`
      margin-right: -36px;

      @media (${({ theme }) => theme.mediaQueries.md}) {
        margin-right: -120px;
      }

      @media (${({ theme }) => theme.mediaQueries.xxl}) {
        margin-right: -278px;
      }
    `};
`

export const Viewport = styled.div`
  overflow: hidden;
  padding-right: 60px;
`

export const Container = styled.div`
  display: flex;
`

export const Slide = styled.div`
  flex: 0 0 auto;
  margin-left: 16px;
  max-width: 100%; /* Prevent from growing larger than viewport */
`
