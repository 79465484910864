// React components
import Layout from '../../components/Layout'
import Career from '../../components/pages/Career'
import { Api } from '../../types/api'
import { provideStaticApiData } from '../../utils/api'
import Seo from '../../components/Seo'

// Constants
import { COLORS } from '../../utils/constants'
import { GET_CAREER_HOME_PAGE } from '../../utils/graphql-queries'

type Props = {
  data?: {
    karieraHomepage: {
      data: Api.CareerHome
    }
    karieraJobPositionTypes: {
      data: Api.CareerJobType[]
    }
  }
}

const CareerPage = ({ data }: Props) => {
  return (
    <>
      <Seo />
      <Layout career={true} darkBackground={true} background={COLORS.grey80}>
        {data?.karieraHomepage.data && (
          <Career
            data={data?.karieraHomepage.data}
            positions={data.karieraJobPositionTypes.data}
          />
        )}
      </Layout>
    </>
  )
}

export const getStaticProps = provideStaticApiData({
  query: GET_CAREER_HOME_PAGE,
})

export default CareerPage
