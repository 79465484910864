const RecycleIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='44'
      height='43.799'
      viewBox='0 0 44 43.799'
    >
      <g
        fill='#d90017'
        fillRule='evenodd'
        data-name='Group 300'
        transform='translate(-118.061 -86.123)'
      >
        <path
          d='M321.908 224.505q1.97-3.674 2.926-5.464c1.448-2.7 1.875-4.8.452-7.592-1.575-3.091-4.058-6.68-5.9-9.721l-7.63 4.43 6.038 9.809c1.6 2.6 3.788 5.414 4.113 8.538zm-8.859-5.692c3.209-.131 4.818-.194 4.818-.194a.879.879 0 00.089.093 17.671 17.671 0 012.424 5.025c.4 1.6-.279 3.015-3.433 3.463-1.292.182-2.635.355-3.9.49v3.137a.508.508 0 01-.283.422.4.4 0 01-.452-.085l-6.494-7.436a.773.773 0 010-1.225l6.494-7.436a.4.4 0 01.452-.085.5.5 0 01.283.418v3.412z'
          data-name='Path 39'
          transform='translate(-164.101 -101.363)'
        ></path>
        <path
          d='M119.334 235.7q2.2 3.541 3.268 5.266c1.617 2.605 3.222 4.024 6.347 4.189 3.467.181 7.816-.177 11.367-.253l-.017-8.817-11.515.321c-3.053.084-6.583.574-9.451-.705zm9.358-4.826c-1.495 2.842-2.242 4.269-2.242 4.269a.729.729 0 00-.122.029 17.662 17.662 0 01-5.566-.414c-1.583-.456-2.47-1.748-1.284-4.708.49-1.2 1.009-2.453 1.529-3.615l-2.644-1.528-.076-.042a.51.51 0 01-.224-.452.41.41 0 01.3-.355l9.687-1.9a.774.774 0 011.06.612l3.193 9.34a.406.406 0 01-.152.439.506.506 0 01-.5.029l-.076-.042z'
          data-name='Path 40'
          transform='translate(0 -119.16)'
        ></path>
        <path
          d='M198.981 86.2q-4.107-.051-6.109-.072c-3.021-.038-5.052.553-6.841 3.1-1.985 2.825-4 6.685-5.814 9.721l7.341 4.725 5.83-9.881c1.548-2.622 3.009-5.861 5.593-7.592zm-.945 10.485q-2.36-4.18-2.364-4.185a.8.8 0 00.042-.118 17.8 17.8 0 013.292-4.476c1.215-1.09 2.763-1.14 4.59 1.453.745 1.06 1.506 2.17 2.2 3.23l2.668-1.411.079-.038a.492.492 0 01.5.055.412.412 0 01.133.443l-3.546 9.193a.768.768 0 01-1.074.566l-9.455-2.331a.4.4 0 01-.279-.363.514.514 0 01.241-.443l.075-.038z'
          data-name='Path 41'
          transform='translate(-54.27)'
        ></path>
      </g>
    </svg>
  )
}
export default RecycleIcon
