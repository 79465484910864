// Next.js
import Link from '../Link'

// React components
import RecycleIcon from '../svgs/icons/RecycleIcon'
import ConditionalWrapper from '../ConditionalWrapper'

// Styled Components
import { StyledRoundCard, IconWrapper, Text } from './RoundCard.styles'

// Types
import { RoundCardProps } from './RoundCard.types'

const RoundCard = ({
  variant = 'grey',
  icon = <RecycleIcon />,
  text,
  href = '',
}: RoundCardProps) => {
  return (
    <ConditionalWrapper
      condition={href}
      wrapper={children => <Link href={href}>{children}</Link>}
    >
      {href ? (
        <StyledRoundCard
          variant={variant}
          link={href}
          whileHover={{ borderRadius: 0 }}
        >
          {typeof icon === 'string' ? (
            <IconWrapper>
              <img src={icon} />
            </IconWrapper>
          ) : (
            <IconWrapper>{icon}</IconWrapper>
          )}

          <Text>{text}</Text>
        </StyledRoundCard>
      ) : (
        <StyledRoundCard
          whileHover={{ borderRadius: 0 }}
          variant={variant}
          as='div'
        >
          {typeof icon === 'string' ? (
            <IconWrapper>
              <img src={icon} />
            </IconWrapper>
          ) : (
            <IconWrapper>{icon}</IconWrapper>
          )}
          <Text>{text}</Text>
        </StyledRoundCard>
      )}
    </ConditionalWrapper>
  )
}
export default RoundCard
