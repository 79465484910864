const QuotationMarks = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='554'
      height='470.9'
      viewBox='0 0 554 470.9'
    >
      <path
        fill='#fff'
        d='M230.207 20.472H5.529v226.217h103.105v3.078c0 83.1-47.706 133.883-107.722 150.811v90.795c118.494-12.311 229.295-86.178 229.295-267.767zm324.706 0H330.234v226.217H433.34v3.078c0 83.1-49.244 133.883-107.722 150.811v90.795c118.494-12.311 229.294-86.178 229.294-267.767z'
        data-name='Path 257'
        transform='translate(-.912 -20.472)'
      ></path>
    </svg>
  )
}
export default QuotationMarks
