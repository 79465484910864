// Types
import { ConditionWrapperProps } from './ConditionalWrapper.types'

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: ConditionWrapperProps) =>
  condition ? <>{wrapper(children)}</> : <>{children}</>

export default ConditionalWrapper
