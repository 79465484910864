import styled, { css } from 'styled-components'

// Types
import { TextBoxProps, OfferCardWrapperProps } from './OfferCard.types'

export const OfferCardWrapper = styled.div<OfferCardWrapperProps>`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;

  background: ${({ image, textRight }) =>
    image
      ? textRight
        ? `url(${image}) center top / cover no-repeat`
        : `url(${image}) center top / cover no-repeat`
      : "url('/images/article-placeholder.webp')"};

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    grid-template-columns: ${({ textRight }) =>
      textRight ? 'auto 840px' : '840px auto'};
    height: 1088px;
  }

  @media (${({ theme }) => theme.mediaQueries.xxxl}) {
    background: ${({ image, textRight }) =>
      image
        ? textRight
          ? `url(${image}) left -1px top / cover no-repeat`
          : `url(${image}) right -1px top / cover no-repeat`
        : "url('/images/article-placeholder.webp')"};
  }
`

export const VideoOverlay = styled.div`
  position: absolute;
  z-index: 6;
  display: flex;
  padding-top: 100px;
  align-items: flex-start;
  justify-content: center;
  inset: 0;
  width: calc(100% - 1px);
  height: 100%;
  background: linear-gradient(
      rgba(230, 6, 6, 0.5) 0%,
      rgba(115, 3, 3, 0.5) 100%
    ),
    url('/images/career-video-placeholder.webp') left top / cover no-repeat;

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    padding-top: 0;
    align-items: center;
    padding-left: calc(50% - 440px);
    justify-content: flex-start;
  }
`

export const VideoWrapper = styled.div`
  position: fixed;
  z-index: 130;
  width: 100%;
  height: 100%;
  inset: 0;
`

export const VideoPlay = styled.div`
  position: relative;
  z-index: 130;
  cursor: pointer;
  width: 122px;
  height: 122px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    width: 180px;
    height: 180px;
  }

  &:hover {
    background: transparent;
    /* Note: currently only Safari supports backdrop-filter */
    backdrop-filter: blur(17px);
    --webkit-backdrop-filter: blur(17px);
    background-color: rgba(255, 255, 255, 0.26);
  }
`

export const VideoPlayTriangle = styled.div`
  width: 63px;
  height: 63px;
  position: relative;
  left: 7px;
  background: transparent;
  opacity: 0.76;
  backdrop-filter: blur(0px);
  --webkit-backdrop-filter: blur(0px);
  background-color: rgba(255, 255, 255, 0.5);
  clip-path: polygon(100% 50%, 0 0, 0 100%);

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    left: 10px;
    width: 94px;
    height: 94px;
  }
`

export const TextBox = styled.div<TextBoxProps>`
  position: relative;
  z-index: 6;
  background: ${({ theme }) => theme.colors.offerCard.textBoxBackground};
  padding: 36px;
  display: flex;
  flex-direction: column;

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    clip-path: polygon(0 0, 100% 0, 63% 100%, 0% 100%);
    height: 100%;
    padding: 230px 330px 30px 140px;
  }

  ${({ textRight }) =>
    textRight &&
    css`
      @media (${({ theme }) => theme.mediaQueries.lg}) {
        order: 2;
        clip-path: polygon(36% 0, 100% 0, 100% 100%, 0 100%);
        height: 100%;
        padding: 30px 140px 100px 330px;

        > h2 {
          margin-top: auto;
        }
      }
    `}
`

export const VideoContainer = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  max-width: 555px;
  max-height: 335px;
  object-fit: cover;
  top: 50%;
  left: 50%;
  right: initial;
  transform: translate(-50%, -50%);

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    @media (min-height: 700px) {
      max-width: 814px;
      max-height: 493px;
    }
  }

  @media (${({ theme }) => theme.mediaQueries.xxl}) {
    transform: translate(-75%, -50%);
  }
`

export const VideoTitle = styled.div`
  position: absolute;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
  top: 3px;
  left: 12px;

  @media (${({ theme }) => theme.mediaQueries.xs}) {
    top: 4px;
  }

  @media (min-width: 420px) {
    top: 6px;
  }

  @media (min-width: 520px) {
    top: 8px;
  }

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    font-size: ${({ theme }) => (18 - 2) / theme.fontSizes.root + 'rem'};

    @media (min-height: 700px) {
      top: 14px;
    }
  }
`

export const Video = styled.video``
