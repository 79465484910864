// React
import { useEffect, useState, useRef } from 'react'

// Styled Components
import {
  OfferCardWrapper,
  TextBox,
  Video,
  VideoOverlay,
  VideoPlay,
  VideoPlayTriangle,
  VideoWrapper,
  VideoContainer,
  VideoTitle,
} from './OfferCard.styles'
import { Title } from '../../styled-components/shared/common'
import Spacer from '../../styled-components/shared/Spacer'

// Artsy Fresnel
import { Media, MediaContextProvider } from '../../utils/media'

// Types
import { OfferCardProps } from './OfferCard.types'

const OfferCard = ({
  title,
  description,
  textRight,
  media,
}: OfferCardProps) => {
  const [videoOpen, setVideoOpen] = useState(false)

  useEffect(() => {
    //Set overflow: hidden to body when video open to prevent background scrolling
    if (videoOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'initial'
    }
  }, [videoOpen])

  return (
    <OfferCardWrapper textRight={textRight} image={media?.attributes.url}>
      {videoOpen && (
        <VideoWrapper
          onClick={e => {
            setVideoOpen(false)
          }}
        >
          <VideoContainer>
            <VideoTitle>{media?.attributes.caption || ''}</VideoTitle>
            <Video
              autoPlay
              muted={false}
              onClick={e => {
                e.stopPropagation()
              }}
              src={media?.attributes.url}
              controls
            />
          </VideoContainer>
        </VideoWrapper>
      )}

      {media?.attributes.url.endsWith('.mp4') && (
        <VideoOverlay>
          <VideoPlay
            onClick={() => {
              setVideoOpen(true)
            }}
          >
            <VideoPlayTriangle />
          </VideoPlay>
        </VideoOverlay>
      )}
      <MediaContextProvider disableDynamicMediaQueries>
        <Media lessThan='lg'>
          <Spacer size={320} when={{ lg: 0 }} />
        </Media>
      </MediaContextProvider>
      <TextBox textRight={textRight}>
        <Title type='red'>{title}</Title>
        <Spacer size={30} />
        <div dangerouslySetInnerHTML={{ __html: description || '' }} />
      </TextBox>
      <div></div>
    </OfferCardWrapper>
  )
}
export default OfferCard
