// Next.js
import Link from '../Link'

// HTML React Parser
import parse from 'html-react-parser'

// React components
import Button from '../Button'

// Styled Components
import {
  TextBlockWrapper,
  Title,
  Content,
  ButtonWrapper,
  Perex,
} from './TextBlock.styles'
import Spacer from '../../styled-components/shared/Spacer'

// Types
import { TextBlockProps } from './TextBlock.types'

const TextBlock = ({
  title,
  content,
  button = false,
  perex,
  id,
}: TextBlockProps) => {
  return (
    <TextBlockWrapper id={id}>
      <Title>{title}</Title>
      {perex && (
        <>
          <Spacer size={30} when={{ lg: 54 }} />
          <Perex>{perex}</Perex>
        </>
      )}
      <Spacer size={30} />
      <Content>
        {typeof content === 'string' ? parse(content ? content : '') : content}
        {button && (
          <ButtonWrapper>
            <Link href='/o-ave' passHref legacyBehavior>
              <Button link variant='outlined' arrow={true}>
                číst více o firemní kultuře
              </Button>
            </Link>
          </ButtonWrapper>
        )}
      </Content>
    </TextBlockWrapper>
  )
}

export default TextBlock
