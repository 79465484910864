import styled from 'styled-components'

export const CareerWrapper = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`

export const TabsWrapper = styled.div`
  margin-top: -276px;
  position: relative;
  z-index: 8;
  min-height: 237px;
`
