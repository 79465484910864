// React
import { useState } from 'react'

// React components
import Button from '../Button'

// React components (svg)
import QuotationMarks from '../svgs/QuotationMarks'

// Styled Components
import {
  QuotesWrapper,
  Buttons,
  QuoteList,
  Quote,
  QuoteText,
  QuotationMarksWrapper,
} from './Quotes.styles'
import Spacer from '../../styled-components/shared/Spacer'
import useDictionaryLine from '../../hooks/useDictionary'
import dictionary from '../../utils/dictionary'

const CITATION_TYPES = [
  'tymova_prace_partnerstvi_a_ohleduplnost',
  'profesionalita_a_vykonnost',
  'flexibilita_a_rust',
]

type Props = {
  data: {
    category: string
    citation: string
  }[]
}

const Quotes = ({ data }: Props) => {
  const [active, setActive] = useState(CITATION_TYPES[0])
  const l1 = useDictionaryLine(dictionary.career.quoteTypes[0])
  const l2 = useDictionaryLine(dictionary.career.quoteTypes[1])
  const l3 = useDictionaryLine(dictionary.career.quoteTypes[2])

  return (
    <QuotesWrapper>
      <Buttons>
        <Button
          variant='outlined'
          active={active === CITATION_TYPES[0]}
          onClick={() => {
            setActive(CITATION_TYPES[0])
          }}
        >
          {l1}
        </Button>
        <Button
          variant='outlined'
          active={active === CITATION_TYPES[1]}
          onClick={() => {
            setActive(CITATION_TYPES[1])
          }}
        >
          {l2}
        </Button>
        <Button
          variant='outlined'
          active={active === CITATION_TYPES[2]}
          onClick={() => {
            setActive(CITATION_TYPES[2])
          }}
        >
          {l3}
        </Button>
      </Buttons>
      <Spacer size={16} />
      <QuoteList>
        {data
          .filter(quote => quote.category === active)
          .map((quote: any, index: number) => (
            <Quote key={index}>
              <QuoteText>{quote.citation}</QuoteText>
            </Quote>
          ))}
      </QuoteList>
      <QuotationMarksWrapper>
        <QuotationMarks />
      </QuotationMarksWrapper>
    </QuotesWrapper>
  )
}
export default Quotes
