import styled from 'styled-components'

export const TextBlockWrapper = styled.div`
  margin-top: -90px;
  padding-top: 90px;

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    margin-top: -120px;
    padding-top: 120px;
  }
`

export const Title = styled.h2`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.textBlock.title};
  font-size: ${({ theme }) => (42 - 2) / theme.fontSizes.root + 'rem'};

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    font-size: ${({ theme }) => (100 - 2) / theme.fontSizes.root + 'rem'};
  }
`

export const Perex = styled.p`
  font-family: 'neue-haas-grotesk-display', sans-serif;

  font-size: ${({ theme }) => (20 - 2) / theme.fontSizes.root + 'rem'};

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    font-size: ${({ theme }) => (24 - 2) / theme.fontSizes.root + 'rem'};
    max-width: 50%;
  }
`

export const Content = styled.div`
  color: ${({ theme }) => theme.colors.textBlock.text};

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    column-count: 2;
    column-gap: 16px;
  }

  h3 {
    color: ${({ theme }) => theme.colors.red100};
    margin-bottom: 18px;
    font-weight: 700;
    font-size: ${({ theme }) => (20 - 2) / theme.fontSizes.root + 'rem'};

    @media (${({ theme }) => theme.mediaQueries.lg}) {
      font-size: ${({ theme }) => (24 - 2) / theme.fontSizes.root + 'rem'};
    }
  }

  h4,
  h5,
  h6 {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.grey120};
    font-size: ${({ theme }) => (16 - 2) / theme.fontSizes.root + 'rem'};
    margin-bottom: 18px;

    @media (${({ theme }) => theme.mediaQueries.lg}) {
      font-size: ${({ theme }) => (18 - 2) / theme.fontSizes.root + 'rem'};
      margin-bottom: 20px;
    }
  }

  p {
    font-size: ${({ theme }) => (16 - 2) / theme.fontSizes.root + 'rem'};
    margin-bottom: 16px;

    @media (${({ theme }) => theme.mediaQueries.lg}) {
      font-size: ${({ theme }) => (18 - 2) / theme.fontSizes.root + 'rem'};
      margin-bottom: 18px;
    }
  }

  ul,
  ol {
    margin-bottom: 18px;

    li::before {
      content: '– ';
      color: ${({ theme }) => theme.colors.red100};
    }
  }
`

export const ButtonWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
`
