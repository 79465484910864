// React
import { useState, useEffect, useCallback } from 'react'

// Artsy Fresnel
import { Media, MediaContextProvider } from '../../utils/media'

// Embla Carousel
import useEmblaCarousel from 'embla-carousel-react'

// React components
import SwiperButton from './SwiperButton'

// Styled Components
import { SwiperWrapper, Viewport, Container } from './Swiper.styles'

// Types
import { SwiperProps } from './Swiper.types'

const Swiper = ({ children, service = false }: SwiperProps) => {
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)

  const [emblaRef, emblaApi] = useEmblaCarousel({
    containScroll: 'trimSnaps',
    loop: true,
    skipSnaps: false,
  })

  const onSelect = useCallback(() => {
    if (!emblaApi) return
    setPrevBtnEnabled(emblaApi.canScrollPrev())
    setNextBtnEnabled(emblaApi.canScrollNext())
  }, [emblaApi])

  useEffect(() => {
    if (!emblaApi) return
    onSelect()
    emblaApi.on('select', onSelect)
  }, [emblaApi, onSelect])

  useEffect(() => {
    if (emblaApi) {
      setPrevBtnEnabled(emblaApi.canScrollPrev())
      setNextBtnEnabled(emblaApi.canScrollNext())
    }
  }, [emblaApi])

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev()
  }, [emblaApi])

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext()
  }, [emblaApi])

  return (
    <SwiperWrapper service={service}>
      <Viewport ref={emblaRef}>
        <Container>{children}</Container>
      </Viewport>
      <MediaContextProvider disableDynamicMediaQueries>
        <Media greaterThanOrEqual='lg'>
          <SwiperButton
            service={service}
            variant='left'
            onClick={scrollPrev}
            hide={!prevBtnEnabled}
          />
          <SwiperButton
            service={service}
            variant='right'
            onClick={scrollNext}
            hide={!nextBtnEnabled}
          />
        </Media>
      </MediaContextProvider>
    </SwiperWrapper>
  )
}
export default Swiper
