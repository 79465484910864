import styled from 'styled-components'

export const QuotesWrapper = styled.div`
  position: relative;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (${({ theme }) => theme.mediaQueries.md}) {
    flex-direction: row;
    justify-content: center;
    gap: 30px;
  }
`

export const QuoteList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 16px;
  background: ${({ theme }) => theme.colors.grey85};

  @media (${({ theme }) => theme.mediaQueries.xl}) {
    background: transparent;
  }
`

export const Quote = styled.div`
  color: ${({ theme }) => theme.colors.grey120};
  padding: 36px 0 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey100};
  text-decoration: none;
  width: 185px;
  max-width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;

  @media (${({ theme }) => theme.mediaQueries.xl}) {
    background: transparent;
    width: 260px;
  }

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey110};
  }
`

export const QuoteText = styled.p`
  font-weight: 700;
  margin-top: auto;
`

export const QuotationMarksWrapper = styled.div`
  display: none;
  position: absolute;
  right: 0px;
  bottom: -300px;
  z-index: -1;

  @media (${({ theme }) => theme.mediaQueries.xl}) {
    display: block;
  }
`
