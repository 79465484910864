import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

// Types
import { StyledRoundCardProps } from './RoundCard.types'

const linkStyles = css`
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.roundCard.text};
    text-decoration: none;
  }
`

export const StyledRoundCard = motion(styled.a<StyledRoundCardProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 32px;
  width: 224px;
  height: 224px;
  border-radius: 50%;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.roundCard.text};
  background-color: ${({ theme, variant }) =>
    variant === 'white' && theme.colors.roundCard.backgroundWhite};
  background-color: ${({ theme, variant }) =>
    (variant === 'grey' || !variant) && theme.colors.roundCard.backgroundGrey};

  &:hover {
    color: ${({ theme }) => theme.colors.roundCard.text};
    text-decoration: none;
  }

  ${({ link }) => link && linkStyles};

  @media (${({ theme }) => theme.mediaQueries.md}) {
    width: 260px;
    height: 260px;
  }
`)

export const Text = styled.p`
  text-align: center;
  text-decoration: none;
  font-size: ${({ theme }) => 14 / theme.fontSizes.root + 'rem'};

  @media (${({ theme }) => theme.mediaQueries.md}) {
    font-size: ${({ theme }) => (18 - 2) / theme.fontSizes.root + 'rem'};
  }
`

export const IconWrapper = styled.div`
  width: 70px;
  height: 70px;
  flex-shrink: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`
